/* 3D Neon Text Styling */
.qanon-text {
  color: #ffffff;
  text-shadow: 
    0 0 5px #ff00ff, 
    0 0 10px #ff00ff, 
    0 0 20px #ff00ff, 
    0 0 40px #ff00ff, 
    0 0 80px #ff00ff,
    0 0 90px #ff00ff,
    0 0 100px #ff00ff;
  font-family: 'Orbitron', sans-serif;
  margin-top: -50px;
}

/* General Neon Effect */
.neon-text {
  text-shadow: 
    0 0 5px #9e00ff, 
    0 0 10px #9e00ff, 
    0 0 20px #ff00ff, 
    0 0 40px #ff00ff, 
    0 0 80px #ff00ff;
}

/* Background and Body Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #0a0a0a;
}

.App-header {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button {
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}
